import About from "./components/about/about";

const AboutMe = () => {
  return (
    <>
      <About />
    </>
  );
};
export default AboutMe;
